var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data)?_c('x-simple-table',{class:{ 'fixedcol--enabled': _vm.settings.fixedColumns },attrs:{"x-options":_vm.tableOptions,"x-container":_vm.tableContainer,"x-context":_vm.self}},[_c('thead',_vm._l((_vm.headers),function(row,index){return _c('tr',{key:("header-" + index),class:{
          'last-section-row': row.isLastSectionRow,
          'first-section-row': row.isFirstSectionRow,
          'font-weight-bold': row.fontWeightBold,
        }},[_vm._l((_vm.cells(row)),function(cell){return [(cell.tooltip)?_c('v-tooltip',{key:cell.key,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('th',_vm._g(_vm._b({key:("header-" + index + "-cell-" + (cell.realIndex)),class:(_vm.settings.fixedColumns &&
                  cell.realIndex < _vm.settings.fixedColumns
                    ? ("fixedcol fixedcol" + (cell.realIndex + 1))
                    : '') +
                  ' ' +
                  (cell.fontWeightBold ? 'font-weight-bold' : '') +
                  ' ' +
                  (cell.color ? ((cell.color) + "--text") : '') +
                  ' ' +
                  (cell.textNoWrap ? "text-no-wrap" : '') +
                  ' ' +
                  (cell.isFirstSectionCol ? "first-section-col" : '') +
                  ' ' +
                  (cell.isLastSectionCol ? "last-section-col" : '') +
                  ' ' +
                  (cell.extraClass || ''),style:(cell.style),attrs:{"rowspan":cell.rowSpan || undefined,"colspan":cell.colSpan || undefined}},'th',attrs,false),on),[(cell && cell.html)?_c('div',{domProps:{"innerHTML":_vm._s(cell.html)}}):[_vm._v(" "+_vm._s(_vm.cellValue(cell))+" ")]],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(cell.tooltip))])]):_c('th',{key:("header-" + index + "-cell-" + (cell.realIndex)),class:(_vm.settings.fixedColumns && cell.realIndex < _vm.settings.fixedColumns
                ? ("fixedcol fixedcol" + (cell.realIndex + 1))
                : '') +
              ' ' +
              (cell.fontWeightBold ? 'font-weight-bold' : '') +
              ' ' +
              (cell.color ? ((cell.color) + "--text") : '') +
              ' ' +
              (cell.textNoWrap ? "text-no-wrap" : '') +
              ' ' +
              (cell.isFirstSectionCol ? "first-section-col" : '') +
              ' ' +
              (cell.isLastSectionCol ? "last-section-col" : '') +
              ' ' +
              (cell.extraClass || ''),style:(cell.style),attrs:{"rowspan":cell.rowSpan || undefined,"colspan":cell.colSpan || undefined}},[(cell && cell.html)?_c('div',{domProps:{"innerHTML":_vm._s(cell.html)}}):[_vm._v(" "+_vm._s(_vm.cellValue(cell))+" ")]],2)]})],2)}),0),_c('tbody',_vm._l((_vm.body),function(row,index){return _c('tr',{key:("row-" + index),class:{
          'last-section-row': row.isLastSectionRow,
          'first-section-row': row.isFirstSectionRow,
          'font-weight-bold': row.fontWeightBold,
        }},_vm._l((_vm.cells(row)),function(cell){return _c('td',{key:("row-" + index + "-cell-" + (cell.realIndex)),class:(_vm.settings.fixedColumns && cell.realIndex < _vm.settings.fixedColumns
              ? ("fixedcol fixedcol" + (cell.realIndex + 1))
              : '') +
            ' ' +
            (cell.fontWeightBold ? 'font-weight-bold' : '') +
            ' ' +
            (cell.color ? ((cell.color) + "--text") : '') +
            ' ' +
            (cell.isFirstSectionCol ? "first-section-col" : '') +
            ' ' +
            (cell.isLastSectionCol ? "last-section-col" : '') +
            ' ' +
            (cell.extraClass || ''),style:(cell.style),attrs:{"rowspan":cell.rowSpan || undefined,"colspan":cell.colSpan || undefined}},[(cell && cell.html)?_c('div',{domProps:{"innerHTML":_vm._s(cell.html)}}):[_vm._v(" "+_vm._s(_vm.cellValue(cell))+" ")]],2)}),0)}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }