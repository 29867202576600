var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.options.attrs.hideLabel && _vm.options.attrs.label)?_c('div',[_c('span',{class:_vm.options.attrs.labelAttrs.class,style:({
        'text-transform': _vm.options.attrs.labelAttrs.uppercase && 'uppercase',
        'font-size': _vm.options.attrs.labelAttrs['font-size'],
        color: _vm.options.attrs.labelAttrs.color,
      })},[_vm._v(_vm._s(_vm.options.attrs.label))]),(_vm.options.attrs.required)?_c('span',{staticClass:"red--text ml-1"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('validation-provider',{ref:"validationProvider",attrs:{"rules":_vm.options.content.rules,"name":_vm.options.attrs.label,"slim":"","immediate":(_vm.options.content.rules && _vm.options.content.rules.custom && true) || false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var valid = ref.valid;
return [(_vm.options.content && _vm.options.content.static)?_c('x-display',_vm._g(_vm._b({attrs:{"value":_vm.model.value,"x-context":_vm.self}},'x-display',_vm.options.attrs,false),_vm.options.on)):_c(_vm.type,_vm._g(_vm._b({tag:"component",attrs:{"value":_vm.model.value,"disabled":_vm.disabled.value,"x-disabled":_vm.disabled,"x-model":_vm.model,"x-container":_vm.inputContainer,"x-context":_vm.self,"loading":_vm.loading.value,"error-messages":errors,"valid":valid,"label":("" + (_vm.attrs.label || '') + ((_vm.attrs.required && ' (*)') || ''))},on:{"input":function($event){_vm.model.value =
          (_vm.options.content &&
            _vm.options.content.filter &&
            _vm.options.content.filter($event)) ||
          $event}},model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}},'component',_vm.attrs,false),_vm.options.on))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }