export default {
    "pickedUp": "Picked Up",
    "departed": "Departed",
    "arrived": "Arrived",
    "onHold": "On Hold",
    "onDelivery": "On Delivery",
    "cancel": "Cancel",
    "onReturn": "On Return",
    "returned": "Returned",
    "delivered": "Delivered",
    "lostItemDamage": "Lost item-damage",
}
