function toBinary(value: string) {
    const codeUnits = new Uint16Array(value.length);
    for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = value.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

function fromBinary(value: string) {
    const binary = atob(value);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

export function encode(value: string) {
    try {
        return btoa(value)
    } catch {
        return toBinary(value)
    }
}

export function decode(value: string) {
    try {
        return atob(value)
    } catch {
        return fromBinary(value)
    }
}
