

































import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      userManager,
      extraFiltersOptions: {
        profitMethod: {
          label: "Cách tính thưởng commission",
          items: [
            {
              value: "",
              text: "Cách tính 1",
            },
            {
              value: "method2",
              text: "Cách tính 2",
            },
            {
              value: "method3",
              text: "Cách tính 3",
            },
          ],
        },
      },
    };
  },
});
