





































































import coreApiClient from "@/services/apis/coreApiClient";
import StatisticsBase from "./StatisticsBase.vue";
import StatisticsTable from "./StatisticsTable.vue";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import defaultComponent from "@common/modules/vuetifyx/utils/defaultComponent";

export default defaultComponent.apply({
  components: { StatisticsBase, StatisticsTable },
  props: [
    "operatorId",
    "user",
    "label",
    "timeKey",
    "passwordProtected",
    "passwordProtectedScenario",
    "disableStartEndTimeFilter",
    "fields",
    "orderFields",
    "isNotOrderStatistics",
    "isAdsStatistics",
    "isPaymentStatistics",
    "initMonth",
    "disableToolbar",
    "urlEnabled",
  ],
  data() {
    return {
      self: this,
      tableContainer: new DataContainer(),
      tableContainers: [],
      data: null,
      dataKey: "",
      tab: "",
    };
  },
  methods: {
    makeQueries(queries: any) {
      if (this.user) {
        queries.userId = this.user._id;
      }
      return queries;
    },
    refreshMakePromisesFunc({ exportBaseQueries, scenario }) {
      const promises: any = {};
      {
        promises.data = coreApiClient.call(
          "statistics",
          this.operatorId,
          {
            payload: JSON.stringify({
              mode: "raw",
              ...this.makeQueries(exportBaseQueries),
            }),
          },
          undefined,
          false,
          scenario
        );
      }
      return promises;
    },
    exportMakeUrlFunc(queries) {
      return {
        path: this.operatorId,
        queries: this.makeQueries(queries),
      };
    },
    refreshed(data) {
      this.data = data;
      this.dataKey = `${new Date().getTime()}`;
      if (this.data?.data?.tables) {
        for (let i = 0; i < this.data.data.tables.length; i++) {
          this.tableContainers[i] = new DataContainer();
        }
      }
      this.contentResized();
    },
    contentResized() {
      this.tableContainer?.value?.contentResized();
      this.tableContainers?.forEach((tableContainer) => {
        tableContainer?.value?.contentResized();
      });
    },
  },
});
