export default {
    "Deposit": "Deposit",
    "SPM_DEDUCT": "SPM_DEDUCT",
    "ADJUSTMENT_CENTER_ADD": "ADJUSTMENT_CENTER_ADD",
    "FSF_COST_PASSING_DEDUCT": "FSF_COST_PASSING_DEDUCT",
    "WITHDRAWAL_COMPLETED": "WITHDRAWAL_COMPLETED",
    "Withdrawal": "Withdrawal",
    "WITHDRAWAL_CREATED": "WITHDRAWAL_CREATED",
    "WITHDRAWAL_CANCELLED": "WITHDRAWAL_CANCELLED",
    "ADJUSTMENT_CENTER_DEDUCT": "ADJUSTMENT_CENTER_DEDUCT",
    "Payment": "Payment",
    "ADJUSTMENT_FOR_RR_AFTER_ESCROW_VERIFIED": "ADJUSTMENT_FOR_RR_AFTER_ESCROW_VERIFIED",
    "ESCROW_VERIFIED_MINUS": "ESCROW_VERIFIED_MINUS",
    "ESCROW_VERIFIED_ADD": "ESCROW_VERIFIED_ADD",
    "SETTLE": "SETTLE",
    "WITHDRAW": "WITHDRAW",
    "LOST_PARCEL_SELLER_ADD": "LOST_PARCEL_SELLER_ADD",
    "JNT_WITHDRAW": "JNT_WITHDRAW",
    "JNT_DEPOSIT": "JNT_DEPOSIT",
    "WHOLESALE": "WHOLESALE",
    "SELLER_COMPENSATE_ADD": "SELLER_COMPENSATE_ADD",
}
