














































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import configProvider from "@common/services/configProvider";

export default Vue.extend({
  props: ["disabled", "scenario"],
  data() {
    return {
      authorized: configProvider.get("DEBUG", false),
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      data: {},
    };
  },
  methods: {
    async submit() {
      let scenario = this.scenario;
      if (!scenario) {
        scenario = "viewProtectedPage";
      }

      let operator = "requestViewProtectedPage";
      if (scenario === "viewProtectedPage2") {
        operator = "requestViewProtectedPage2";
      }

      this.error = "";
      this.loading = true;
      try {
        const { access_token: accessToken } = await coreApiClient.callRaw("auth", operator, undefined, {
          username: userManager.getUserInfo().email,
          password: this.data.password,
          deviceId: userManager.getDeviceId(),
          scenario,
        });
        if (accessToken) {
          userManager.setAccessTokenForScenario(scenario, accessToken);
          this.authorized = true;
          this.$emit("ready");
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    if (!this.disabled && this.authorized) {
      this.$emit("ready");
    }
  },
});
