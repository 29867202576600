



































































































































import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import defaultComponent from "@common/modules/vuetifyx/utils/defaultComponent";
import Vue from "vue";
export default defaultComponent.apply({
  props: ["data"],
  data() {
    return {
      self: this,
      tableOptions: null,
      tableContainer: new DataContainer(),
      rows: [],
      settings: {},
    };
  },
  computed: {
    headers() {
      return this.rows.filter((row) => row.isHeader);
    },
    body() {
      return this.rows.filter((row) => !row.isHeader);
    },
  },
  methods: {
    makeTableOptions() {
      return {
        attrs: {
          "fixed-header": !this.data.settings?.fixedHeaderDisabled,
        },
      };
    },
    cells(row) {
      return row.cells || row;
    },
    cellValue(cell) {
      let value = typeof cell === "object" ? cell.value : cell;
      if (!value) {
        return "";
      }
      if (cell?.type === "percentage") {
        value = Vue.filter("percentage")(value);
        if (value) {
          value = `${value}%`;
        } else {
          value = "";
        }
      } else if (cell?.type === "double") {
        value = Vue.filter("number3")(value);
      } else if (cell?.type === "date") {
        value = Vue.filter("date")(value);
      } else if (!isNaN(value)) {
        value = Vue.filter("currency")(value);
      }
      return value;
    },
    contentResized() {
      this.$nextTick(() => {
        const self = this;
        const timer = setInterval(() => {
          const table = self.tableContainer.value;
          if (!table) {
            return;
          }
          table.recalculateHeight();
          clearInterval(timer);
        }, 200);
      });
    },
  },
  created() {
    this.rows = this.data.items || [];
    this.settings = this.data.settings || {};
    this.tableOptions = this.makeTableOptions();
  },
});
